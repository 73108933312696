import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react';
import firebase from '@happylife-a/firebase';
import utils from '@happylife-a/utils';
import webApp from '@happylife-a/web-app';
import webCore from '@happylife-a/web-core';
import { Flex, Center, Spinner } from '@chakra-ui/react';
import ThreadDetails from '~components/Chat/ThreadMessageTab/ThreadDetails';
import useWindowDimensions from '~hooks/useWindowDimensions';
import EmptyStateChannel from '../../Channels/EmptyState';
import EmptyStateGroup from '../../Groups/EmptyState';
import EmptyState from '../../MessagesTab/EmptyState';
import ChannelMessage from '../ChannelMessage';

export default function ChannelThreadMessageList({
  chatRoom,
  shouldUpdateScrollTop,
  setShouldUpdateScrollTop,
  onReply,
  setShowMessageParams,
  setEditMessage,
  editMessage,
  firebaseThreadId,
  showMessageParams,
  roomType,
  products,
  isImageSelected,
  newState,
  reply,
}) {
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [messageCardHeight, setMessageCardHeight] = useState(0);

  const contentRef = useRef(null);

  const { dispatch } = webApp.contexts.useModal();
  const { height } = useWindowDimensions();
  const { user } = webCore.contexts.useUser();

  const screenSize = webApp.contexts.useScreenSize();

  const chatLib =
    firebase.libraries.messaging[showMessageParams?.messagingType];

  const hookLib = webCore.coreHooks.messaging[showMessageParams?.messagingType];

  const onOpenForwardModal = (message) => {
    dispatch({
      type: 'open',
      modal: webApp.constants.ENUM_MODAL_FORWARD_MESSAGE,
      props: {
        message: message,
        chatRoom: chatRoom,
        setShowMessageParams: setShowMessageParams,
      },
    });
  };

  const { messageContext } = webCore.contexts.useMessaging();
  const messageContextParams = useMemo(
    () => ({
      messagingType: showMessageParams?.messagingType,
      firebaseRoomId: chatRoom?.firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    }),
    []
  );

  const { messages, loadNextPageMessages, loadedMessagesCount, isLoading } =
    messageContext.getByConfigs(messageContextParams);

  useEffect(() => {
    messageContext.configure(messageContextParams);
  }, []);

  const updateMessage = hookLib.useUpdateMessage({
    firebaseRoomId: chatRoom?.firebaseRoomId,
    firebaseThreadId: firebaseThreadId,
    messageId: selectedMessage?.id,
  });

  const onMessageHover = useCallback((e, message) => {
    setMessageCardHeight(e.currentTarget.offsetHeight);
    setSelectedMessage(message);
  }, []);

  const onMessageHoverLeave = useCallback(() => {
    setSelectedMessage(null);
  }, []);

  const handleScroll = (event) => {
    const { scrollTop } = event.target;
    if (scrollTop === 0 && loadedMessagesCount < messages.length) {
      loadNextPageMessages(() => {
        setShouldUpdateScrollTop(false);
      });
    }
  };

  const onOpenDeleteModal = () => {
    dispatch({
      type: 'open',
      modal: webApp.constants.ENUM_MODAL_DELETE_MESSAGE,
      props: {
        firebaseRoomId: chatRoom?.firebaseRoomId,
        firebaseThreadId: firebaseThreadId,
        messageId: selectedMessage?.id,
      },
    });
  };

  const onAddReaction = useCallback(
    (newReactionName) => {
      const messageInfo = chatLib.message.toggleReaction(
        selectedMessage,
        newReactionName,
        user.id
      );

      updateMessage(messageInfo);
    },
    [selectedMessage]
  );

  useEffect(() => {
    if (contentRef.current) {
      const contentHeight = contentRef.current.scrollHeight;
      if (shouldUpdateScrollTop) {
        contentRef.current.scrollTop = contentHeight;
      } else {
        contentRef.current.scrollTop = 5;
      }
    }
  }, [messages, shouldUpdateScrollTop]);

  const groupedMessages =
    utils.helpers.groupMessage.sortAndGroupMessages(messages);

  const flatListData =
    utils.helpers.groupMessage.flattenMessages(groupedMessages);

  return (
    <webApp.components.ScrollableBox
      as={Flex}
      ref={contentRef}
      onScroll={handleScroll}
      overflowY="scroll"
      px={2}
      flexDir="column"
      pt={4}
      gap={6}
      height={
        screenSize.isMinTablet
          ? height - 520
          : height -
            (products?.length > 0
              ? 364
              : isImageSelected
                ? 313
                : reply
                  ? 214
                  : 144)
      }
    >
      {isLoading && (
        <Center>
          <Spinner size="sm" />
        </Center>
      )}

      {!screenSize.isMinTablet && <ThreadDetails details={newState?.message} />}
      {flatListData?.length > 0 ? (
        <>
          {flatListData?.reverse().map((item, idx) => (
            <>
              {item?.type === 'date' ? (
                <webApp.components.MessageDate
                  key={`message-${messages.id}-${idx}`}
                  date={item.date}
                />
              ) : (
                <ChannelMessage
                  key={`message-${messages.id}-${idx}`}
                  message={item}
                  chatRoom={chatRoom?.chatRoom}
                  firebaseRoomId={chatRoom?.firebaseRoomId}
                  firebaseThreadId={firebaseThreadId}
                  messageId={selectedMessage?.id}
                  onOver={onMessageHover}
                  onMouseLeave={onMessageHoverLeave}
                  onReply={onReply}
                  onOpenForwardModal={onOpenForwardModal}
                  setEditMessage={setEditMessage}
                  editMessage={editMessage}
                  onOpenDeleteModal={onOpenDeleteModal}
                  messageCardHeight={messageCardHeight}
                  onAddReaction={onAddReaction}
                  setShowMessageParams={setShowMessageParams}
                  messagingType={showMessageParams?.messagingType}
                  roomType={roomType}
                />
              )}
            </>
          ))}
        </>
      ) : (
        <>
          {chatRoom?.chatRoom?.type ===
          firebase.libraries.messaging.chatting.constants
            .CHAT_ROOM_TYPE_CHANNEL ? (
            <EmptyStateChannel chatRoom={chatRoom?.chatRoom} />
          ) : chatRoom?.chatRoom?.type ===
            firebase.libraries.messaging.chatting.constants
              .CHAT_ROOM_TYPE_GROUP ? (
            <EmptyStateGroup chatRoom={chatRoom?.chatRoom} />
          ) : (
            <EmptyState chatRoom={chatRoom?.chatRoom} />
          )}
        </>
      )}
    </webApp.components.ScrollableBox>
  );
}
