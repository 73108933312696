import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import webApp from '@happylife-a/web-app';
import webCore from '@happylife-a/web-core';

const Chat = webCore.helpers.dynamic(() => import('~components/Chat'));

const ChatVisibilityContext = createContext({
  isVisible: false,
  setIsVisible: (_isVisible) => {},
});

export function ChatVisibilityProvider({ forceHidden, children }) {
  const [isMounted, setIsMounted] = useState(false);
  const [isOpenChatTab, setIsOpenChatTab] = useState(false);
  const [isOpenMessageTab, setIsOpenMessageTab] = useState(false);
  const [firebaseRoomId, setFirebaseRoomId] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      setIsMounted(true);
    }, 1000);
  }, []);

  const [isVisible, setIsVisible] = useState(true);

  const screenSize = webApp.contexts.useScreenSize();

  const canShowChat =
    !forceHidden && isMounted && isVisible && screenSize.isMinTablet;

  const setIsVisibleCallback = useCallback((value) => {
    setIsVisible(value);
    setIsMounted(true);
  }, []);

  return (
    <ChatVisibilityContext.Provider
      value={{
        isVisible: isVisible,
        setIsVisible: setIsVisibleCallback,
        isOpenChatTab: isOpenChatTab,
        setIsOpenChatTab: setIsOpenChatTab,
        isOpenMessageTab: isOpenMessageTab,
        setIsOpenMessageTab: setIsOpenMessageTab,
        firebaseRoomId: firebaseRoomId,
        setFirebaseRoomId: setFirebaseRoomId,
      }}
    >
      {children}
      {canShowChat && <Chat />}
    </ChatVisibilityContext.Provider>
  );
}

export function useChatVisibility() {
  return useContext(ChatVisibilityContext);
}

export function useHideChatForThisPage() {
  const chatVisibility = useChatVisibility();
  useEffect(() => {
    const isVisible = chatVisibility.isVisible;
    chatVisibility.setIsVisible(false);

    return () => {
      chatVisibility.setIsVisible(isVisible);
    };
  }, []);
}
