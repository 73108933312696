import appBranding from '@happylife-a/branding';
import webCore from '@happylife-a/web-core';

const MainLayout = webCore.helpers.dynamic(() => import('~layouts/Main'));
const NotFound = webCore.helpers.dynamic(() => import('~pages/404'));

// agreements
const AgreementWithPartner = webCore.helpers.dynamic(
  () => import('~pages/agreement-with-partner/index')
);

// categories & products & seller
const CategoryById = webCore.helpers.dynamic(
  () => import('~pages/categories/[id]')
);

const MobileCategoryById = webCore.helpers.dynamic(
  () => import('~pages/category/[id]')
);
const Categories = webCore.helpers.dynamic(() => import('~pages/category'));

// favorites & shopping cart
const CheckTransaction = webCore.helpers.dynamic(
  () => import('~pages/check-transaction/index')
);

//track order
const TrackYourOrder = webCore.helpers.dynamic(
  () => import('~pages/track-order/index')
);

//Contact us
const ContactUs = webCore.helpers.dynamic(
  () => import('~pages/contact-us/index')
);
const DeliveryTermsAndConditions = webCore.helpers.dynamic(
  () => import('~pages/delivery-terms-and-conditions/index')
);
const ReturnTermsAndConditions = webCore.helpers.dynamic(
  () => import('~pages/return-terms-and-conditions/index')
);
const Favorites = webCore.helpers.dynamic(
  () => import('~pages/favorites/index')
);
const HomePage = webCore.helpers.dynamic(() => import('~pages/index'));
const PrivacyPolicy = webCore.helpers.dynamic(
  () => import('~pages/privacy-policy/index')
);
const ProductById = webCore.helpers.dynamic(
  () => import('~pages/products/[id-or-slug]')
);
const ProductList = webCore.helpers.dynamic(
  () => import('~pages/products/index')
);
const SellerPrivacyPolicy = webCore.helpers.dynamic(
  () => import('~pages/seller-privacy-policy/index')
);

//Sender address
const SellerTermsAndConditions = webCore.helpers.dynamic(
  () => import('~pages/seller-terms-and-conditions/index')
);
const SellerById = webCore.helpers.dynamic(() => import('~pages/sellers/[id]'));
const ShoppingCart = webCore.helpers.dynamic(
  () => import('~pages/shopping-cart/index')
);
const TermsAndConditions = webCore.helpers.dynamic(
  () => import('~pages/terms-and-conditions/index')
);
const ConfirmSignup = webCore.helpers.dynamic(
  () => import('~pages/user/confirm-sign-up')
);
const ForgotPassword = webCore.helpers.dynamic(
  () => import('~pages/user/forgot-password')
);

//Addresses
const Delivery = webCore.helpers.dynamic(
  () => import('~pages/addresses-mobile/add-edit-delivery-address')
);
const Pickup = webCore.helpers.dynamic(
  () => import('~pages/addresses-mobile/add-edit-pickup-address')
);
const DeleteDelivery = webCore.helpers.dynamic(
  () => import('~pages/addresses-mobile/delete-delivery-address')
);
const DeletePickup = webCore.helpers.dynamic(
  () => import('~pages/addresses-mobile/delete-pickup-address')
);
const ManageDeliveryAddress = webCore.helpers.dynamic(
  () => import('~pages/addresses-mobile/manage-delivery-address')
);
const ManagePickupAddress = webCore.helpers.dynamic(
  () => import('~pages/addresses-mobile/manage-pickup-address')
);
const ManagePickupAddressNameSurname = webCore.helpers.dynamic(
  () => import('~pages/addresses-mobile/manage-pickup-address-name-surname')
);
const ViewDeliveryAddress = webCore.helpers.dynamic(
  () => import('~pages/addresses-mobile/view-delivery-address')
);
const ViewPickupAddress = webCore.helpers.dynamic(
  () => import('~pages/addresses-mobile/view-pickup-address')
);

//Other
const LogOut = webCore.helpers.dynamic(() => import('~pages/user/log-out'));

//Chat
const Chat = webCore.helpers.dynamic(() => import('~pages/chat'));

const screens = [
  {
    pathname: '/',
    screen: HomePage,
    layout: MainLayout,
  },

  // agreements
  {
    pathname: '/agreement-with-partner',
    screen: AgreementWithPartner,
    layout: MainLayout,
  },
  {
    pathname: '/delivery-terms-and-conditions',
    screen: DeliveryTermsAndConditions,
    layout: MainLayout,
  },
  {
    pathname: '/return-terms-and-conditions',
    screen: ReturnTermsAndConditions,
    layout: MainLayout,
  },
  {
    pathname: '/privacy-policy',
    screen: PrivacyPolicy,
  },
  {
    pathname: '/terms-and-conditions',
    screen: TermsAndConditions,
  },

  // categories & products & seller
  {
    pathname: '/category',
    screen: Categories,
    layout: MainLayout,
    layoutParams: {
      showMobileSearch: false,
    },
  },
  {
    pathname: '/categories/:id',
    screen: CategoryById,
    layout: MainLayout,
    layoutParams: {
      showMobileSearch: false,
    },
  },
  {
    pathname: '/category/:id',
    screen: MobileCategoryById,
    layout: MainLayout,
    layoutParams: {
      showMobileSearch: false,
    },
  },
  {
    pathname: '/products',
    screen: ProductList,
    layout: MainLayout,
  },
  {
    pathname: '/products/:idOrSlug',
    screen: ProductById,
    layout: MainLayout,
    layoutParams: {
      hasMobileBottomSpacing: false,
      showMobileSearch: false,
    },
  },
  {
    pathname: '/sellers/:id',
    screen: SellerById,
    layout: MainLayout,
  },

  // favorites
  {
    pathname: '/check-transaction/:type/:paymentProvider',
    screen: CheckTransaction,
    layout: MainLayout,
  },
  {
    pathname: '/favorites',
    screen: Favorites,
    layout: MainLayout,
    layoutParams: {
      showMobileSearch: false,
    },
  },
  {
    pathname: '/shopping-cart',
    screen: ShoppingCart,
    layout: MainLayout,
    layoutParams: {
      showMobileSearch: false,
    },
  },
  {
    pathname: '/contact-us',
    screen: ContactUs,
  },
  {
    layout: MainLayout,
    layoutParams: {
      showMobileSearch: false,
    },
    pathname: '/track-order',
    screen: TrackYourOrder,
  },
  {
    pathname: '/seller-privacy-policy',
    screen: SellerPrivacyPolicy,
  },
  {
    pathname: '/seller-terms-and-conditions',
    screen: SellerTermsAndConditions,
  },
  {
    pathname: '/user/confirm-sign-up',
    screen: ConfirmSignup,
    layout: MainLayout,
    layoutParams: {
      showMobileSearch: false,
    },
  },
  {
    pathname: '/user/forgot-password',
    screen: ForgotPassword,
    layout: MainLayout,
    layoutParams: {
      showMobileSearch: false,
    },
  },
  {
    pathname: '/user/log-out',
    screen: LogOut,
    layout: MainLayout,
    layoutParams: {
      showMobileSearch: false,
    },
  },
  !appBranding.isFeatureEnabled('chat')
    ? null
    : {
        pathname: '/chat',
        screen: Chat,
        layout: MainLayout,
        layoutParams: {
          showMobileSearch: false,
        },
      },

  // addresses
  {
    pathname: '/addresses/view-delivery-address',
    screen: ViewDeliveryAddress,
    layout: MainLayout,
    layoutParams: {
      showMobileSearch: false,
    },
  },
  {
    pathname: '/addresses/manage-delivery-address',
    screen: ManageDeliveryAddress,
    layout: MainLayout,
    layoutParams: {
      showMobileSearch: false,
    },
  },
  {
    pathname: '/addresses/add-delivery-address',
    screen: Delivery,
    layout: MainLayout,
    layoutParams: {
      showMobileSearch: false,
    },
  },
  {
    pathname: '/addresses/edit-delivery-address/:id',
    screen: Delivery,
    layout: MainLayout,
    layoutParams: {
      showMobileSearch: false,
    },
  },
  {
    pathname: '/addresses/delete-delivery-address/:id',
    screen: DeleteDelivery,
    layout: MainLayout,
    layoutParams: {
      showMobileSearch: false,
    },
  },
  {
    pathname: '/addresses/view-pickup-address',
    screen: ViewPickupAddress,
    layout: MainLayout,
    layoutParams: {
      showMobileSearch: false,
    },
  },
  {
    pathname: '/addresses/manage-pickup-address',
    screen: ManagePickupAddress,
    layout: MainLayout,
    layoutParams: {
      showMobileSearch: false,
    },
  },
  {
    pathname: '/addresses/manage-pickup-address-name-surname',
    screen: ManagePickupAddressNameSurname,
    layout: MainLayout,
    layoutParams: {
      showMobileSearch: false,
    },
  },
  {
    pathname: '/addresses/add-pickup-address',
    screen: Pickup,
    layout: MainLayout,
    layoutParams: {
      showMobileSearch: false,
    },
  },
  {
    pathname: '/addresses/delete-pickup-address/:id',
    screen: DeletePickup,
    layout: MainLayout,
    layoutParams: {
      showMobileSearch: false,
    },
  },
  // #########################################################
  // #######       ALWAYS KEEP 404 IN LAST PLACE       #######
  // #########################################################

  // the not-found route must be end of the list.
  {
    pathname: '*',
    screen: NotFound,
    layout: MainLayout,
  },
];

export default screens;
